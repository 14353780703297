*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}

html,
body {
  font-family: suit, -apple-system, Roboto, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior: contain;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  color: inherit;
  pointer-events: auto;
  text-decoration: none;
  display: inline-block;
}

button {
  background: none;
  border: none;
  border-radius: none;
  color: inherit;
  font: inherit;
  pointer-events: auto;
}

input,
textarea {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  pointer-events: auto;
}

img,
svg {
  vertical-align: center;
}

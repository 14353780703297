@font-face {
  font-family: "suit";
  font-display: block;
  font-weight: 800;
  src: url("../../fonts/SUIT/SUIT-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "suit";
  font-display: block;
  font-weight: 700;
  src: url("../../fonts/SUIT/SUIT-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "suit";
  font-display: block;
  font-weight: 600;
  src: url("../../fonts/SUIT/SUIT-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "suit";
  font-display: block;
  font-weight: 400;
  src: url("../../fonts/SUIT/SUIT-Light.woff2") format("woff2");
}
